export const urls = {
  home: {
    base: "/home",
    about: "/home/about",
    contact: "/home/contact",
    categories: "/home/categories",
    privacy: "/home/privacy_policy",
  },
  register: {
    base: "/register",
  },
};
