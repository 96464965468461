import React from "react";
import OtpInput from "react-otp-input";
import i18n from "i18next";
import { lowerCase } from "lodash";
import { Button, Toast } from "common/components";
import { api } from "common/api/api";
import { useUpdateEffect } from "ahooks";

const Courier = () => {
    const { t } = i18n;

    const language = lowerCase(localStorage.getItem("lang"));

    const [otp, setOtp] = React.useState("");

    const [verify, verifyState] = api.useDailyOrderOtpMutation();

    const handleChange = (otp) => setOtp(otp);

    const submitOtp = () => {
        verify({
            body: { order_otp: otp },
            language,
        });
    };

    useUpdateEffect(() => {
        if (!verifyState.isLoading) {
            if (verifyState.isSuccess) {
                Toast.fire({
                    icon: "success",
                    title: verifyState.data.message,
                });
            }

            if (verifyState.isError) {
                Toast.fire({
                    icon: "error",
                    title: verifyState.error?.data?.message,
                });
            }
        }
    }, [verifyState.isLoading]);

    return (
        <div
            className="otp"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <h2 className="mb-3">{t("verifyOrderOTP")}</h2>
            <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={3}
                separator={<span>-</span>}
            />
            <Button
                className="mt-3"
                type="primary"
                onClick={submitOtp}
                isLoading={verifyState.isLoading}
                disabled={otp?.length !== 3}
            >
                {t("verify")}
            </Button>
        </div>
    );
};

export default Courier;
