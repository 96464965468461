import React from "react";
import { Button as CoreButton, Row, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./style/index.scss";

const Button = ({
    children,
    className,
    type,
    onClick,
    htmlType,
    style,
    isLoading,
    disabled,
    tooltipText,
}) => {
    return (
        <>
            {!tooltipText ? (
                <CoreButton
                    className={`button button-${type} ${className}`}
                    onClick={onClick}
                    htmlType={htmlType}
                    style={{ ...style }}
                    disabled={disabled}
                >
                    {isLoading ? (
                        <div className="button-loader"></div>
                    ) : (
                        children
                    )}
                </CoreButton>
            ) : (
                <Row align="middle">
                    <Space>
                        <CoreButton
                            className={`button button-${type} ${className}`}
                            onClick={onClick}
                            htmlType={htmlType}
                            style={{ ...style }}
                            disabled={disabled}
                        >
                            {isLoading ? (
                                <div className="button-loader"></div>
                            ) : (
                                children
                            )}
                        </CoreButton>
                        <Tooltip placement="top" title={tooltipText} arrow>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Space>
                </Row>
            )}
        </>
    );
};

export default Button;
