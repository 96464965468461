import {urls} from "shared/constants/url";

export const footerLinks = [
    {
        title: "home",
        link: urls.home.base,
    },
    {
        title: "about",
        link: urls.home.about,
    },
    {
        title: "privacy",
        link: urls.home.privacy,
    },
    {
        title: "categories",
        link: urls.home.categories,
    },
    {
        title: "contact",
        link: urls.home.contact,
    },
    {
        title: "faqs",
        link: "/faqs",
    },
];
