import React, {useEffect, useState} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { TopHeader, Header, Footer } from "modules";
import { Home, About, Contact, Categories, Packets, Privacy } from "pages";
import Popup from "../Home/Popup";
import androidButton from "../../bg/android.png";
import appleButton from "../../bg/apple.png";

import './../../App.css';
import burger from "../../bg/bg.jpg";
import background from "../../bg/bg.jpg";

const Main = ({ match: { url } }) => {
    const [bg, setBg] = useState(null)

    useEffect(() => {
        if (window.screen.width < 576) {
            setBg(burger)
        } else {
            setBg(background)
        }
    }, [])

  return (
      <div className="App" style={{ backgroundImage: `url(${bg})` }}>
          <a href="https://play.google.com/store/apps/details?id=com.mobileapp.ayyemek" target="_blank" rel="noopener noreferrer">
              <img className="buttons" src={androidButton} alt="Download on Android" />
          </a>
          <a href="https://apps.apple.com/us/app/ayyemek/id6448846359" target="_blank" rel="noopener noreferrer">
              <img className="buttons" src={appleButton} alt="Download on Apple" />
          </a>
      </div>
    // <>
    //     <Popup />
    //   <TopHeader />
    //   <Header />
    //   <Switch>
    //     <Route path={`${url}`} exact render={() => <Home />} />
    //     <Route path={`${url}/about`} render={() => <About />} />
    //     <Route path={`${url}/privacy_policy`} render={() => <Privacy />} />
    //     <Route path={`${url}/contact`} render={() => <Contact />} />
    //     <Route path={`${url}/categories`} render={() => <Categories />} />
    //     <Route path={`${url}/category/:id`} render={() => <Packets />} />
    //     <Redirect to="/" />
    //   </Switch>
    //   <Footer />
    // </>
  );
};

export default Main;
